<template>
  <section class="content">
    <div class="container-fluid">
      <custom-alert v-if="displayAlert" id="alert_box" :message="alertMessage" :variant="alertVariant" v-on:showedAlert="resetAlert"></custom-alert>
      <div class="row">
        <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
          <div class="card">
            <div class="card-body table-responsive table-head pt-2">
              <div class="row">
                <div class="col-md-8 text-left px-0 bor-bot pb-3">
                  <h1 class="m-0 text-dark pt-2 text-left px-0">Certificate of Insurances</h1>
                </div>
                <div class="col-md-4 bor-bot pr-0">
                  <div class="text-right pb-3 pt-1">
                    <ol class="breadcrumb float-sm-right pr-0">
                      <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                      <li class="breadcrumb-item"><Breadcrumbs/></li>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mt-3 showentries pl-0">
                </div>
                <div class="col-md-6 pr-0">
                  <div class="mt-3 mb-3 text-right pr-0 emltmpserchsec">
                    <div class=" card-text d-flex align-items-center float-right mb-0">
                      <div>
                        <a href="#" @click="addCertificate()" class="btn btn darkblubtn btn-outline-primary btn-outline-primary">
                          <i class="fa fa-plus-circle mr-2"></i>Add Certificate
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <b-overlay :show="showLoader" no-wrap></b-overlay>
                <div class="col-lg-2 col-md-2 mb-4" v-for="(file,index) in list" :key="'file-'+index">
                  <div class="card custom-card shadow" @mouseover="overlayId = file.id" @mouseleave="overlayId = null">
                    <b-overlay :show="overlayId === file.id">
                      <img :src="file.icon_image" alt="Icon Image" class="card-img-top">
                      <div class="card-body text-center">
                        <h5>{{ file.year }}</h5>
                      </div>
                      <template #overlay>
                        <div class="text-center">
                          <button class="btn darkblubtn btn-sm mr-2" v-b-tooltip.hover title="Download" :disabled="downloading" @click="download(file.id, file.year)">
                            <i class="fa" :class="downloading ? 'fa-spinner fa-spin' : 'fa-download'"></i>
                          </button>
                          <button class="btn btn-danger btn-sm" v-b-tooltip.hover title="Delete" @click="deleteFile(file.id, file.year)" :disabled="deleting">
                            <i class="fa" :class="deleting ? 'fa-spinner fa-spin' : 'fa-trash'"></i>
                          </button>
                        </div>
                      </template>
                    </b-overlay>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <b-modal
        id="add-certificate"
        title="Add Certificate"
        size="md"
        ref="modal"
        ok-title-html="<i class='fa fa-save mr-2'></i>Save"
        @ok.prevent="doAddCertificate"
    >
      <b-overlay :show="saving" no-wrap></b-overlay>
      <div class="form-group">
        <label class="form-label font-weight-bold">Year<sup class="text-danger">*</sup></label>
        <select class="form-control" v-model="form.year">
          <option value="">Select</option>
          <option v-for="(item,index) in years" :key="'year'+index">{{ item }}</option>
        </select>
        <small class="text-danger" v-if="formErrors.year" v-html="formErrors.year"></small>
      </div>
      <div class="form-group">
        <label class="form-label font-weight-bold">File<sup class="text-danger">*</sup></label><br>
        <b-form-file
            v-model="form.file"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".pdf"
            ref="certificateFile"
        ></b-form-file>
        <small class="text-danger" v-if="formErrors.file" v-html="formErrors.file"></small>
      </div>
    </b-modal>
  </section>
</template>
<script>
import axios from 'axios';
import CustomAlert from "../CustomAlert";

export default {
  name: "CertificateOfInsurances",
  components: {CustomAlert},
  data(){
    return {
      list: [],
      showLoader: false,
      overlayId: null,
      years: [],
      form: {
        year: "",
        file: ""
      },
      formErrors: {
        year: "",
        file: ""
      },
      downloading: false,
      deleting: false,
      saving: false
    };
  },
  methods: {
    fetchInfo() {
      this.showLoader = true;
      axios.get(this.basePath+"api/club-insurance-certificates", { headers: this.adminHeaders })
      .then(response => {
        this.list = response.data.data;
        this.showLoader = false;
      })
      .catch(err => console.log(err));
    },
    download(id, year) {
      this.downloading = true;
      axios.get(
          this.basePath+"api/club-insurance-certificates/download/"+id,
          { headers: this.adminHeaders, responseType: 'blob' }
      )
      .then(response => {
        const link = document.createElement('a');
        const blob = new Blob([response.data]);
        link.href = window.URL.createObjectURL(blob);
        link.download = "club_insurance_certificate-"+year+".pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.downloading = false;
      })
      .catch(err => console.log(err));
    },
    deleteFile(id, year) {
      this.$bvModal.msgBoxConfirm("Are you certain to delete this "+year+" file ?", {
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value.toString() === "true") {
          axios.delete(this.basePath+"api/club-insurance-certificates/delete/"+id, { headers: this.adminHeaders })
          .then(res => {
            if (res.data.status === "success") {
              this.alertMessage="Certificate is deleted";
              this.displayAlert = true;
              this.fetchInfo();
            } else {
              this.$bvModal.msgBoxOk(res?.data?.message);
            }
          })
          .catch(err => console.log(err));
        }
      }).catch(err => console.log(err));
    },
    addCertificate() {
      this.$root.$emit("bv::show::modal", "add-certificate");
    },
    doAddCertificate() {
      this.formErrors = { year: "", file: "" };
      if (!this.form.year) {
        this.formErrors.year = "Year is required";
      }
      if (!this.form.file) {
        this.formErrors.file = "File is required";
      }
      if (!Object.values(this.formErrors).filter(item => item !== '').length) {
        let formData = new FormData();
        formData.append('year', this.form.year);
        formData.append('file', this.form.file);
        let headers = {
          "Accept": "application/json",
          'Content-Type': 'multipart/form-data',
          "Authorization": "Bearer " + localStorage.getItem('admintoken')
        };
        this.saving = true;
        axios.post(this.basePath+"api/club-insurance-certificates/store", formData, { headers: headers })
        .then(res => {
          if (res.data.status === "success") {
            this.alertMessage = res.data.message;
            this.alertVariant = "success";
            this.displayAlert = true;
            this.fetchInfo();
          } else {
            this.alertMessage = res.data.message;
            this.alertVariant = "danger";
            this.displayAlert = true;
          }
          this.$bvModal.hide("add-certificate");
          this.saving = false;
          this.form = { year: "", file: "" };
        })
        .catch(err => console.log(err));
      }
    }
  },
  mounted() {
    this.fetchInfo();
    // Create an array of years (adjust the range as needed)
    for (let year = 2016; year <= new Date().getFullYear() + 10; year++) {
      this.years.push(year);
    }
  }
}
</script>
<style scoped>
.custom-card {
  position: relative;
  transition: transform 0.3s;
}

.custom-card:hover {
  transform: translateY(-10px); /* Adjust the translation value for the desired lift */
}
</style>
